import React from 'react'
import { useLocation } from 'react-router-dom'
import './tickets.scss'
import Menu from '../../components/menu/menu'
import OtherProjects from '../../components/otherProjects/otherProjects'

// images
const computer = '/imgSvg/tickets/computer.svg'
const computer2 = '/imgSvg/tickets/computer2.svg'
const cards = '/imgSvg/tickets/cards.svg'

// icons

const icon1 = '/imgSvg/tickets/ico/icon2.svg'
const icon2 = '/imgSvg/tickets/ico/icon3.svg'
const icon3 = '/imgSvg/tickets/ico/icon4.svg'
const icon4 = '/imgSvg/tickets/ico/icon5.svg'
const icon5 = '/imgSvg/tickets/ico/icon6.svg'
const icon6 = '/imgSvg/tickets/ico/icon7.svg'



const Tickets = () => {

   const location = useLocation();

   return (
      <div>
         <Menu mode="activeWhite"/>
         <div className="relative">
            <div className="hidden sm:block"><img className="w-full hidden sm:block" src='./img/tickets/banner.png' /></div>

            <div className="block sm:hidden">
               <img className="tickets__phoneBg" src='./img/tickets/banner.png' />
               <div className="tickets__bannerOverlay"></div>
            </div>

            <div className="absoluteSection">
               <div className="text-center sm:text-left text-4xl sm:text-3xl md:text-4xl lg:text-5xl font-bold" data-aos="flip-up">Print tickets</div>
               <div className="text-center sm:text-left text-lg lg:text-2xl mt-2 sm:mt-1 lg:mt-6">Save your money by comparing prices</div>
            </div>
         </div>

         <div className="work__section px-10">
            <img className="mx-auto mt-10 py-5 md:py-20" src={computer} />
         </div>

         <div className="work__section flex py-5 md:py-20 flex-wrap flex-col md:flex-row justify-between">

            <div className="flex flex-col mt-10 mr-10">
               <div>
                  <div className="text-gray uppercase text-base font-medium">colors</div>
                  <div className="flex mt-3 flex-wrap" data-aos="zoom-in">
                     <div className="bg-tickets-lightGray w-12 h-12 rounded-full mr-3"></div>
                     <div className="bg-tickets-gray w-12 h-12 rounded-full mr-3"></div>
                     <div className="bg-tickets-black w-12 h-12 rounded-full mr-3"></div>
                     <div className="bg-tickets-blue w-12 h-12 rounded-full mr-3"></div>
                  </div>
               </div>

               <div className="mt-10 lg:mt-20">
                  <div className="text-gray uppercase text-base font-medium">typography</div>
                  <div className="mt-3 text-4xl sm:text-6xl font-medium tickets__typography text-tickets-blue" data-aos="zoom-in">Monserrat</div>
                  <div className="flex mt-3 items-center mt-10" data-aos="zoom-in">
                     <div className="flex flex-col">
                        <div className="">Medium</div>
                        <div className="text-5xl tickets__typography font-medium">Aa</div>
                     </div>
                     <div className="flex flex-col ml-16 mt-1">
                        <div className="font-medium">Semibold</div>
                        <div className="text-5xl font-semibold tickets__typography">Aa</div>
                     </div>
                  </div>
               </div>
            </div>

            <div className="flex flex-col flex-wrap mt-10 w-full lg:w-auto">
               <div>
                  <div className="text-gray uppercase text-base font-medium">icons</div>
                  <div className="mt-3 rw__colorsSectionIcons">
                     <img src={icon1} />
                     <img src={icon2} />
                     <img src={icon3} />
                     <img src={icon4} />
                     <img src={icon5} />
                     <img src={icon6} />
                  </div>
               </div>
               <div className="mt-10 lg:mt-20">
                  <div className="text-gray uppercase text-base font-medium">card ui</div>
                  <div className="mt-3">
                     <div><img src={cards} /></div>
                  </div>
               </div>
            </div>
         </div>

         <div className="work__section flex flex-col md:flex-row justify-center md:justify-between py-10">
            <div className="flex-2"><img src={computer2} className="" /></div>
         <div className="flex flex-col pl-0 md:pl-10">
               <div className="text-2xl md:text-3xl lg:text-4xl mx-auto text-center md:w-4/5 md:mx-0 md:text-left whitespace-nowrap" data-aos="fade-left">Print tickets</div>
               <div className="text-base md:text-lg md:w-4/5 my-1 md:my-5 text-center md:text-left text-dog-gray" data-aos="fade-left">Simple interface for ticketing</div>
            </div>
         </div>

         <OtherProjects location={location.pathname} />

      </div>
   )
}

export default Tickets

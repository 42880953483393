import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import './vocabulary.scss'
import AnimatedBg from '../../components/rwAnimatedBg/rwAnimatedBg'
import Menu from '../../components/menu/menu';
import OtherProjects from '../../components/otherProjects/otherProjects'

// img 
const logo = '/imgSvg/rw/logo.svg'
const pc = '/imgSvg/rw/pc.svg'
const phone = '/imgSvg/rw/phone.svg'
const translation = '/imgSvg/rw/translation.svg'

// icons
const icon1 = '/imgSvg/rw/ico/icon1.svg';
const icon2 = '/imgSvg/rw/ico/icon2.svg';
const icon3 = '/imgSvg/rw/ico/icon3.svg';
const icon4 = '/imgSvg/rw/ico/icon4.svg';
const icon5 = '/imgSvg/rw/ico/icon5.svg';
const icon6 = '/imgSvg/rw/ico/icon6.svg';
const icon7 = '/imgSvg/rw/ico/icon7.svg';
const icon8 = '/imgSvg/rw/ico/icon8.svg';
const icon9 = '/imgSvg/rw/ico/icon9.svg';
const icon10 = '/imgSvg/rw/ico/icon10.svg';
const icon11 = '/imgSvg/rw/ico/icon11.svg';
const icon12 = '/imgSvg/rw/ico/icon12.svg';
const icon13 = '/imgSvg/rw/ico/icon13.svg';
const icon14 = '/imgSvg/rw/ico/icon14.svg';
const icon15 = '/imgSvg/rw/ico/icon15.svg';
const icon16 = '/imgSvg/rw/ico/icon16.svg';

const folders = '/imgSvg/rw/folders.svg'
const pcAndPhone = '/imgSvg/rw/pcAndPhone.svg'
const laptop2 = '/imgSvg/rw/pc2.svg'

const Vocabulary = (props) => {

   const location = useLocation();

   return (
      <div>
         <Menu />
         <div className="relative">

            <div className="rw__tabletBgWrapper">
               <img src='./img/rw/header-background.jpg' className="rw__tabletBg" />
            </div>
            <div className="rw__headerBgWrapper">
               <AnimatedBg />
            </div>
            <div className="rw__banner" >
               <div className="mx-auto"><img src={logo} data-aos="zoom-in-up" data-aos-delay="300"/></div>
               <div className="text-dark text-xl text-center mt-10" data-aos="zoom-in-up" data-aos-delay="300">Take your language learning to the next level</div>
            </div>
         </div>

         <div className="rw__section py-14 justify-center md:py-20 lg:py-24 flex flex-col-reverse md:flex-row md:justify-between">
            <div className="flex-1 text-2xl md:text-3xl lg:text-4xl mt-10 mx-auto text-center w-3/5 md:w-auto md:mx-0 md:text-left" >Things what helps you to get better</div>
            <div className="rw__thingsSection ml-0 md:ml-10">
               <div className="rw__thingsSection--pc">
                  <img src={pc} className="" />
               </div>
               <div className="rw__thingsSection--pc ml-5">
                  <img src={phone} className="" />
               </div>
            </div>
         </div>

         <div className="rw__browserSection--bg py-14 md:py-20 lg:py-24">
            <div className="rw__section flex flex-col md:flex-row justify-center md:justify-between">
               <div className="flex-2"><img src={translation} className="" /></div>
               <div className="rw__browserSection--text text-2xl md:text-3xl lg:text-4xl md:ml-20 mx-auto text-center md:text-left mt-5 md:mt-0 w-1/2" data-aos="fade-left">Add new words while surfing the browser</div>
            </div>
         </div>

         <div className="rw__section flex py-12 md:py-20 flex-wrap flex-col md:flex-row">
            <div className="flex flex-col mt-10 mr-10">
               <div>
                  <div className="text-gray uppercase text-base font-medium">colors</div>
                  <div className="flex mt-3 flex-wrap" data-aos="zoom-in" data-aos-delay="200">
                     <div className="bg-rw-gray w-12 h-12 rounded-full mr-3"></div>
                     <div className="bg-rw-yellow w-12 h-12 rounded-full mr-3"></div>
                     <div className="bg-rw-purple w-12 h-12 rounded-full mr-3"></div>
                     <div className="bg-rw-black w-12 h-12 rounded-full mr-3"></div>
                  </div>
               </div>

               <div className="mt-10 lg:mt-20">
                  <div className="text-gray uppercase text-base font-medium">typography</div>
                  <div className="mt-3 text-4xl sm:text-6xl text-rw-purple font-light" data-aos="zoom-in" data-aos-delay="300">Poppins</div>
                  <div className="flex mt-3 items-center mt-10" data-aos="zoom-in" data-aos-delay="300">
                     <div className="flex flex-col">
                        <div className="">Regular</div>
                        <div className="text-5xl">Aa</div>
                     </div>
                     <div className="flex flex-col ml-16 mt-1">
                        <div className="font-medium">Medium</div>
                        <div className="text-5xl font-medium">Aa</div>
                     </div>
                  </div>
               </div>
            </div>

            <div className="flex flex-col flex-wrap mt-10 w-full lg:w-auto flex-1 items-start lg:items-center">
               <div>
                  <div className="text-gray uppercase text-base font-medium">icons</div>
                  <div className="mt-3 rw__colorsSectionIcons">
                     <img src={icon1} />
                     <img src={icon2} />
                     <img src={icon3} />
                     <img src={icon4} />
                     <img src={icon5} />
                     <img src={icon6} />
                     <img src={icon7} />
                     <img src={icon8} />
                     <img src={icon9} />
                     <img src={icon10} />
                     <img src={icon11} />
                     <img src={icon12} />
                     <img src={icon13} />
                     <img src={icon14} />
                     <img src={icon15} />
                     <img src={icon16} />
                  </div>
               </div>
               <div className="mt-10 lg:mt-20">
                  <div className="text-gray uppercase text-base font-medium">card ui</div>
                  <div className="mt-3">
                     <div><img src={folders} /></div>
                  </div>
               </div>
            </div>
         </div>

         <div className="rw__section flex flex-col-reverse md:flex-row justify-center md:justify-between py-12 md:py-24">
            <div className="flex-2"><img src={pcAndPhone} className="" /></div>
            <div className="text-2xl md:text-3xl lg:text-4xl md:ml-20 mx-auto text-center md:text-left mb-5 md:mt-0 w-3/5 md:w-auto" data-aos="fade-left">Manage your words and practicer</div>
         </div>

         <div className="rw__section flex flex-col-reverse md:flex-row justify-center md:justify-between py-12 md:py-24">
            <div className="text-2xl md:text-3xl lg:text-4xl md:ml-20 mx-auto text-center md:text-left mt-5 md:mt-0 w-3/5 md:w-auto" data-aos="fade-left">Things what helps you to get better</div>
            <div className="flex-2"><img src={laptop2} className="" /></div>
         </div>

         <div className="rw__browserSection--bg py-14 md:py-20 lg:py-24">
            <div className="mx-auto w-10/12"><img src="./img/rw/phones.png" className="mx-auto" /></div>
         </div>

         <OtherProjects location={location.pathname} />

      </div>
   )
}

export default Vocabulary

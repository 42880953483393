import React, { useEffect } from 'react'
import './otherProjects.scss'
import { NavLink } from "react-router-dom"
import { ContentData } from '../../contentData'
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';


const OtherProjects = (props) => {

   const [projects, setProjects] = React.useState(ContentData.Projects);

   useEffect(() => {
      let projects = ContentData.Projects.filter(project => project.link !== props.location)
      setProjects(projects)
   }, []);

   const scrollToTop = () => {
      window.scrollTo(0, 0)
   }

   return (
      <div className="otherProjects__section py-20">
         <div className="font-semibold text-2xl">Other projects</div>
         <div className="otherProjects">

            {projects.map(x => {
               return (
                  <NavLink to={x.link} onClick={scrollToTop}>
                     <div className="otherProjects__box" data-aos="fade-up">
                        <img className="otherProjects__img" src={x.img} />

                        <div className="otherProjects__desc">
                           <div>
                              <div className={x.color === "light" ? "text-lg text-white font-semibold" : "text-lg text-dark font-semibold"}>{x.name}</div>
                              <div className={x.color === "light" ? "text-descWhite text-sm" : "text-gray text-sm"}>{x.desc}</div>
                           </div>
                           <div className={x.color === "light" ? "otherProjects__arrow text-white" : "otherProjects__arrow text-gray"}><ArrowForwardIosRoundedIcon /></div>
                        </div>
                     </div>
                  </NavLink>
               )
            })}

         </div>
      </div>
   )
}

export default OtherProjects

import React, {useEffect} from 'react';
import { useLocation } from 'react-router-dom'
import { BrowserRouter as Router, Switch, Route, HashRouter } from "react-router-dom";
import './App.scss';
import Aos from 'aos'
import 'aos/dist/aos.css'

import HomePage from './pages/homePage/homePage';
import Vocabulary from './pages/vocabulary/vocabulary';
import DogWalking from './pages/dogWalking/dogWalking';
import Tickets from './pages/tickets/tickets';
import Monit from './pages/monit/monit';
import Twist from './pages/twist/twist';


function App() {

  useEffect(() => {
    Aos.init({
       duration: 1000,
       startEvent: 'DOMContentLoaded',
    });
 }, [])

  return (
    <HashRouter>
    <>
      <Switch>
          <Route path="/" exact component={HomePage} />
          <Route path="/rw" exact component={Vocabulary} />
          <Route path="/dogWalking" exact component={DogWalking} />
          <Route path="/tickets" exact component={Tickets} />
          <Route path="/monit" exact component={Monit} />
          <Route path="/twist" exact component={Twist} />
        </Switch>
    </>
    </HashRouter>
   
  );
}

export default App;

import React from 'react'
import './twist.scss'
import { useLocation } from 'react-router-dom'
import Menu from '../../components/menu/menu'
import OtherProjects from '../../components/otherProjects/otherProjects'

// images
const logo = '/imgSvg/twist/logo.svg'
const triangle = '/imgSvg/twist/triangle.svg'
const triangleWhite = '/imgSvg/twist/triangleWhite.svg'
const laptopAndPhone = '/imgSvg/twist/laptopAndPhone.svg'
const laptop = '/imgSvg/twist/laptop.svg'
const phones = '/imgSvg/twist/phones.svg'
const cards = '/imgSvg/twist/cards.svg'

// icons 
const icon1 = '/imgSvg/twist/ico/icon1.svg'
const icon2 = '/imgSvg/twist/ico/icon2.svg'
const icon3 = '/imgSvg/twist/ico/icon3.svg'
const icon4 = '/imgSvg/twist/ico/icon4.svg'
const icon5 = '/imgSvg/twist/ico/icon5.svg'
const icon6 = '/imgSvg/twist/ico/icon6.svg'
const icon7 = '/imgSvg/twist/ico/icon7.svg'
const icon8 = '/imgSvg/twist/ico/icon8.svg'
const icon9 = '/imgSvg/twist/ico/icon9.svg'
const icon10 = '/imgSvg/twist/ico/icon10.svg'
const icon11 = '/imgSvg/twist/ico/icon11.svg'



const Twist = () => {

   const location = useLocation();

   return (
      <div>
         <Menu mode="white" />

         <div className="relative z-10">
            <div className="hidden sm:block"><img className="w-full hidden sm:block" src='./img/twist/banner.png' /></div>

            <div className="block sm:hidden">
               <img className="tickets__phoneBg" src='./img/twist/banner.png' />
            </div>

            <div className="absoluteSection pt-0 sm:pt-20 lg:pt-0">
               <div className="" data-aos="fade-in"><img src={logo} className="w-auto sm:w-32 lg:w-auto mx-auto sm:mx-0" /></div>
               <div className="text-center sm:text-left text-lg md:text-lg lg:text-2xl mt-6 sm:mt-4 lg:mt-6 text-white font-regular sm:font-light">Watch movies, series and TV shows online</div>
            </div>
         </div>

         <div className="relative">
            <img className="w-10/12 md:w-auto -mt-2" src={triangle} />
            <div className="absoluteSection flex justify-center py-20 lg:py-24 flex flex-col lg:flex-row md:justify-between items-center">
               <div className="mt-7 md:mt-0 w-9/12 md:w-8/12 lg:w-auto">
                  <img src={laptopAndPhone} className="mx-auto" /></div>
               <div className="flex flex-col mt-10 md:mt-0 ml-0 md:ml-10">
                  <div className="text-xl md:text-3xl lg:text-4xl mx-auto text-center md:mx-0 md:text-left" data-aos="fade-left">Looking for a movie</div>
                  <div className="text-base md:text-lg my-2 text-center sm:text-left text-twist-gray" data-aos="fade-left">Select the film offered by the site</div>
               </div>

            </div>
         </div>

         <div className="work__section flex justify-center flex flex-col-reverse md:flex-row md:justify-between items-center">
            <div className="flex flex-col mt-10 md:mt-0 mr-0 md:mr-10">
               <div className="text-xl md:text-3xl lg:text-4xl mx-auto text-center md:mx-0 md:text-left" data-aos="fade-left">Find your favorite actor</div>
               <div className="text-base md:text-lg my-2 text-center md:text-left text-twist-gray w-10/12 mx-auto md:mx-0 md:w-3/5" data-aos="fade-left">On the page of each actor proposed films in which he was made</div>
            </div>
            <div className="mt-7 md:mt-0 w-9/12 md:w-8/12 lg:w-auto"><img src={phones} className="mx-auto" /></div>
         </div>



         <div className="work__section flex py-5 md:py-20 flex-wrap flex-col md:flex-row justify-between">

            <div className="flex flex-col mt-10 mr-10">
               <div>
                  <div className="text-gray uppercase text-base font-medium">colors</div>
                  <div className="flex mt-3 flex-wrap" data-aos="zoom-in" data-aos-delay="200">
                     <div className="bg-twist-yellow w-12 h-12 rounded-full mr-3"></div>
                     <div className="bg-twist-dark w-12 h-12 rounded-full mr-3"></div>
                     <div className="bg-twist-black w-12 h-12 rounded-full mr-3"></div>
                  </div>
               </div>

               <div className="mt-10 lg:mt-20">
                  <div className="text-gray uppercase text-base font-medium">typography</div>
                  <div className="mt-3 text-4xl sm:text-6xl text-twist-blue font-medium twist__typography" data-aos="zoom-in" data-aos-delay="300">Jost</div>
                  <div className="flex mt-3 items-center mt-10" data-aos="zoom-in" data-aos-delay="300">
                     <div className="flex flex-col">
                        <div className="font-regular text-sm sm:text-base">Regular</div>
                        <div className="text-3xl sm:text-5xl twist__typography">Aa</div>
                     </div>
                     <div className="flex flex-col ml-14 mt-1">
                        <div className="font-medium text-sm sm:text-base">Medium</div>
                        <div className="text-3xl sm:text-5xl font-medium twist__typography">Aa</div>
                     </div>
                     <div className="flex flex-col ml-14 mt-1">
                        <div className="font-semibold text-sm sm:text-base">Bold</div>
                        <div className="text-3xl sm:text-5xl font-bold twist__typography">Aa</div>
                     </div>
                  </div>
               </div>
            </div>

            <div className="flex flex-col flex-wrap mt-10 w-full lg:w-auto">
               <div>
                  <div className="text-gray uppercase text-base font-medium">icons</div>
                  <div className="mt-3 rw__colorsSectionIcons">
                     <img src={icon1} />
                     <img src={icon2} />
                     <img src={icon3} />
                     <img src={icon4} />
                     <img src={icon5} />
                     <img src={icon6} />
                     <img src={icon7} />
                     <img src={icon8} />
                     <img src={icon9} />
                     <img src={icon10} />
                     <img src={icon11} />
                  </div>
               </div>
               <div className="mt-10 lg:mt-20">
                  <div className="text-gray uppercase text-base font-medium">card ui</div>
                  <div className="mt-3">
                     <div><img src={cards} /></div>
                  </div>
               </div>
            </div>
         </div>

         <div className="relative overflow-hidden mt-10 pb-0 sm:pb-12 md:pb-20">
            <div className="bg-twist-dark w-full h-full absolute top-0 sm:top-28"></div>
            <div className="absolute top-0 block sm:hidden"><img src={triangleWhite} /></div>


         <div className="work__section flex justify-center flex flex-col md:flex-row md:justify-between items-center py-0 pb-10 sm:pb-0 sm:py-10">
            <div className="mt-0 w-9/12 md:w-8/12 lg:w-auto z-20"><img src={laptop} className="mx-auto" /></div>

            <div className="flex flex-col mt-10 md:mt-28 ml-0 md:ml-10 w-auto md:w-4/12 z-20">
               <div className="text-xl md:text-3xl lg:text-4xl mx-auto text-center md:mx-0 md:text-left text-white">Find your favorite actor</div>
               <div className="text-base md:text-lg my-2 text-center md:text-left text-twist-lightGray w-10/12 mx-auto md:mx-0">On the page of each actor proposed films in which he was made</div>
            </div>
         </div>
         </div>

         <OtherProjects location={location.pathname} />

      </div>
   )
}

export default Twist

import React from 'react'
import './rwAnimatedBg.scss'

const AnimatedBg = () => {
   return (
      // <svg className="rw__bg" height="512" viewBox="0 0 1440 512" fill="none" xmlns="http://www.w3.org/2000/svg">
      <svg className="rw__bg" viewBox="0 0 1440 512" fill="none" xmlns="http://www.w3.org/2000/svg">
         <g id="bg" clip-path="url(#clip0)">
            <rect width="1440" height="512" fill="white" />
            <circle id="yellowShadow" opacity="0.4" cx="674" cy="721" r="406" fill="url(#paint0_radial)" />
            <circle id="Ellipse 5" opacity="0.3" cx="89.5" cy="814.5" r="484.5" fill="url(#paint1_radial)" />
            <circle id="purpleShadow" opacity="0.3" cx="1258" cy="260" r="584" fill="url(#paint2_radial)" />
            <circle id="pinkShadow" opacity="0.3" cx="207.376" cy="169.376" r="331.804" transform="rotate(-15 207.376 169.376)" fill="url(#paint3_radial)" fill-opacity="0.6" />
            <ellipse id="Ellipse 3" opacity="0.3" cx="715" cy="126" rx="447" ry="450" fill="url(#paint4_radial)" />
            <g id="Rectangle 1" filter="url(#filter0_b)">
               <rect width="1440" height="608" fill="url(#paint5_linear)" fill-opacity="0.4" />
            </g>
            <g id="Group" opacity="0.5">
               <path id="Vector" data-aos="fade-up-left" d="M1357.08 6.24668C1330.52 34.9123 1291.56 50.4643 1252.45 51.4435C1251.17 51.4627 1249.88 51.4819 1248.6 51.4819C1220.83 51.4819 1193.2 44.1091 1170.05 28.8259C1156.24 19.7059 1143.9 8.14749 1131.97 -3.02691C1116.52 -17.5037 1100.54 -32.4797 1081.4 -41.9645C1044.02 -60.5309 996.019 -58.4381 938.765 -35.7437C923.52 -29.7149 908.314 -22.6877 893.606 -15.8909C874.714 -7.17412 855.398 1.75388 835.469 8.83868C824.294 12.8131 812.928 16.1923 801.331 18.6691C741.6 31.3219 676.282 17.6707 626.63 -17.8685C596.966 -39.1037 572.909 -68.1341 556.781 -100.985V-101.005H558.912C558.912 -101.005 558.912 -101.005 558.931 -100.985C574.886 -68.7485 598.598 -40.2941 627.744 -19.4237C676.973 15.8083 741.715 29.3443 800.928 16.7875C813.37 14.1571 825.581 10.4131 837.562 6.03548C856.454 -0.838119 874.81 -9.32451 892.8 -17.6381C907.546 -24.4349 922.771 -31.4813 938.054 -37.5293C995.846 -60.4349 1044.36 -62.5085 1082.27 -43.6925C1101.64 -34.0733 1117.73 -19.0013 1133.28 -4.42851C1145.16 6.70749 1157.43 18.1891 1171.1 27.2131C1194.95 42.9571 1223.62 50.1955 1252.22 49.5427C1290.89 48.6403 1329.41 33.2803 1355.65 4.94108C1381.77 -23.2445 1394.15 -62.5661 1392.27 -100.985C1392.27 -101.005 1392.27 -101.005 1392.27 -101.005H1394.17C1394.17 -101.005 1394.17 -101.005 1394.17 -100.985C1396.07 -62.0861 1383.51 -22.2845 1357.08 6.24668Z" fill="white" />
               <path id="Vector_2"  data-aos="fade-up-right" d="M162.01 445.6C155.923 466.72 142.349 486.074 128.256 506.215C112.8 528.256 96.8256 551.066 91.488 576.659C86.7648 599.296 90.72 622.759 94.5408 645.434C97.2672 661.504 100.07 678.131 99.8592 694.567C99.2064 745.677 67.5456 794.368 21.1008 815.738C14.2656 818.867 7.2192 821.383 0 823.264V821.306C6.9504 819.443 13.7472 817.005 20.3136 813.991C66.0864 792.928 97.2864 744.928 97.9392 694.528C98.1504 678.285 95.3472 661.735 92.6592 645.741C88.8 622.893 84.8064 599.258 89.6064 576.275C95.0208 550.279 111.11 527.315 126.682 505.101C140.544 485.325 153.888 466.259 159.994 445.619C162.874 435.962 164.141 425.959 163.162 415.36C160.55 387.328 139.834 359.872 109.114 343.667C81.0816 328.883 49.3056 323.949 18.5856 319.168L0 316.288V314.33L18.8736 317.267C49.7664 322.087 81.696 327.04 109.997 341.978C141.293 358.471 162.394 386.522 165.062 415.187C166.061 425.863 164.832 435.923 162.01 445.6Z" fill="white" />
               <path id="Vector_3" data-aos="fade-up-left" d="M1361.03 301.6C1336.63 326.215 1323.76 358.144 1326.64 387.002C1327.99 400.404 1332.56 413.402 1336.99 425.978C1340.45 435.789 1344 445.927 1346.07 456.276C1347.57 463.86 1348.28 471.52 1348.44 479.22C1348.8 494.964 1346.88 510.804 1345.02 526.298C1342.87 544.25 1340.64 562.74 1341.96 580.864C1341.96 580.884 1341.96 580.884 1341.96 580.884C1342.16 583.802 1342.46 586.682 1342.87 589.562C1347.19 620.378 1365.58 649.485 1393.34 669.453C1407.76 679.821 1423.72 687.002 1440 690.708V692.685C1423.32 688.941 1406.98 681.626 1392.23 671.008C1364.04 650.752 1345.34 621.165 1340.97 589.831C1340.74 588.16 1340.52 586.471 1340.37 584.8C1340.37 584.781 1340.37 584.781 1340.37 584.781C1338.41 565.255 1340.79 545.364 1343.12 526.068C1345 510.535 1346.9 494.656 1346.52 478.932C1346.34 471.444 1345.65 463.994 1344.19 456.64C1342.16 446.426 1338.6 436.365 1335.17 426.612C1330.71 413.94 1326.11 400.807 1324.74 387.194C1321.8 357.78 1334.86 325.274 1359.67 300.237C1381.19 278.541 1409.84 263.661 1440 257.863V259.783C1410.32 265.562 1382.19 280.231 1361.03 301.6Z" fill="white" />
               <path id="Vector_4" data-aos="fade-up-right" d="M519.744 146.656C509.222 137.632 496.877 132.487 484.954 127.514C469.92 121.255 454.368 114.784 442.234 100.48C435.053 92.0131 428.736 82.1251 422.035 71.6419C413.414 58.1251 404.64 44.3971 393.773 33.5875C382.906 22.7971 369.946 14.9251 352.934 13.1779C351.533 13.0435 350.15 12.9475 348.768 12.9475C324.691 12.4291 300.71 28.7107 290.611 52.9987C281.069 75.9619 283.718 104.32 297.658 128.839C307.104 145.447 321.293 160.211 339.917 172.883C344.467 175.975 349.267 178.951 354.336 181.792C383.155 197.92 414.528 206.291 444.518 206.291C458.227 206.291 471.648 204.544 484.397 200.992C504.73 195.347 526.656 183.213 529.862 168.851C531.533 161.325 528.134 153.856 519.744 146.656ZM527.981 168.429C524.966 182.023 503.654 193.658 483.878 199.149C443.693 210.304 396.806 203.373 355.277 180.103C350.074 177.184 345.158 174.151 340.531 170.983C322.387 158.541 308.544 144.103 299.328 127.898C285.677 103.879 283.085 76.1539 292.397 53.7283C301.901 30.8419 325.133 14.8483 347.885 14.8483C348.077 14.8483 348.25 14.8483 348.442 14.8675C349.882 14.8675 351.322 14.9443 352.742 15.0979C385.843 18.4963 403.43 46.0483 420.422 72.6787C427.142 83.2195 433.498 93.1651 440.755 101.709C453.216 116.378 468.96 122.944 484.205 129.299C496.013 134.215 508.205 139.303 518.496 148.115C526.31 154.816 529.498 161.651 527.981 168.429Z" fill="white" />
            </g>
            <g id="Group_2" opacity="0.5">
               <path id="Vector_5" data-aos="fade-up-right" d="M485.606 526.298C483.341 494.522 458.669 467.987 417.926 453.491C398.726 446.656 378.528 443.354 359.386 443.354C327.706 443.354 298.906 452.397 282.278 469.312C281.971 469.619 281.664 469.926 281.376 470.234C256.032 497.21 251.194 540.237 270.163 570.304C283.738 591.808 309.331 604.365 334.138 604.346C344.986 604.346 355.68 601.965 365.126 596.858C370.771 593.824 376.128 589.907 381.331 586.125C383.117 584.819 384.883 583.533 386.63 582.304C395.021 576.352 403.354 571.36 413.222 570.381C419.232 569.786 425.395 570.822 431.366 571.84C437.434 572.877 443.731 573.933 449.99 573.338C472.781 571.072 487.046 546.726 485.606 526.298ZM449.798 571.418C443.789 572.013 437.626 570.957 431.674 569.958C425.606 568.922 419.309 567.846 413.05 568.48C402.989 569.478 394.291 574.586 386.054 580.365C384.077 581.747 382.138 583.149 380.198 584.57C375.053 588.314 369.754 592.192 364.224 595.168C333.965 611.469 290.765 599.373 271.795 569.267C253.267 539.91 258.01 497.92 282.778 471.558C283.219 471.098 283.661 470.637 284.141 470.195C310.483 443.93 367.219 437.478 417.274 455.296C457.286 469.523 481.478 495.462 483.686 526.432C485.414 550.797 467.213 569.69 449.798 571.418Z" fill="white" />
               <path id="Vector_6" data-aos="fade-up-right" d="M1099.79 128.973C1091.5 106.355 1074.34 95.9297 1050.12 98.8289C1024.86 101.863 1001.97 118.739 987.341 145.12C983.961 151.245 981.12 157.619 978.854 164.128C969.523 190.874 969.734 219.827 980.179 242.023C992.87 269.075 1020.69 287.469 1048.43 287.469C1049.22 287.469 1050.01 287.45 1050.78 287.431C1072.44 286.605 1092.61 273.587 1103.19 255.789C1107.49 248.57 1110.2 240.583 1110.82 232.307C1112.6 208.019 1109.13 154.413 1099.79 128.973ZM1108.9 232.173C1108.34 239.719 1105.98 247.034 1102.19 253.677C1092.15 271.514 1072.2 284.704 1050.7 285.511C1022.9 286.528 994.656 268.327 981.907 241.216C971.616 219.29 971.481 190.605 980.889 164.147C983.078 157.946 985.785 151.879 989.03 146.042C1003.35 120.199 1025.7 103.687 1050.35 100.73C1073.57 97.9457 1090.04 107.949 1097.99 129.626C1107.23 154.835 1110.68 208.039 1108.9 232.173Z" fill="white" />
            </g>
         </g>
         <defs>
            <filter id="filter0_b" x="-500" y="-500" width="2440" height="1608" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
               <feFlood flood-opacity="0" result="BackgroundImageFix" />
               <feGaussianBlur in="BackgroundImage" stdDeviation="250" />
               <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur" />
               <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur" result="shape" />
            </filter>
            <radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(674 721) rotate(89.9161) scale(369.239 407.754)">
               <stop offset="0.484375" stopColor="#FFCE7A" />
               <stop offset="1" stopColor="#C4C4C4" stop-opacity="0" />
            </radialGradient>
            <radialGradient id="paint1_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(89.5 814.5) rotate(89.9161) scale(440.631 486.593)">
               <stop offset="0.484375" stopColor="#6A75CA" />
               <stop offset="1" stopColor="#C4C4C4" stop-opacity="0" />
            </radialGradient>
            <radialGradient id="paint2_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(1258 260) rotate(89.9161) scale(531.122 586.523)">
               <stop offset="0.484375" stopColor="#6A75CA" />
               <stop offset="1" stopColor="#C4C4C4" stop-opacity="0" />
            </radialGradient>
            <radialGradient id="paint3_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(207.376 169.376) rotate(89.9161) scale(301.761 333.238)">
               <stop offset="0.484375" stopColor="#F777BA" />
               <stop offset="1" stopColor="#C4C4C4" stop-opacity="0" />
            </radialGradient>
            <radialGradient id="paint4_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(715 126) rotate(89.9167) scale(409.255 448.931)">
               <stop offset="0.484375" stopColor="#6A75CA" />
               <stop offset="1" stopColor="#C4C4C4" stop-opacity="0" />
            </radialGradient>
            <linearGradient id="paint5_linear" x1="0" y1="0" x2="947.785" y2="1129.45" gradientUnits="userSpaceOnUse">
               <stop stopColor="white" />
               <stop offset="1" stopColor="white" stop-opacity="0" />
            </linearGradient>
            <clipPath id="clip0">
               <rect width="1440" height="512" fill="white" />
            </clipPath>
         </defs>
      </svg>
   )
}

export default AnimatedBg

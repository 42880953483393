import React, { useEffect } from 'react';
import './homePage.scss'
import { ContentData } from '../../contentData';
import { NavLink } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';
import Masonry from 'react-masonry-css'
import Slider from "react-slick";

import Menu from '../../components/menu/menu';
import Footer from '../../components/footer/footer';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';

// images

const feedback_illustration = '/imgSvg/bgIllustrationFeedbackSection.svg';
const banner_illustration = '/imgSvg/banner_illustration.svg';

const formIllustration = '/imgSvg/formIllustration.svg';
const formIllustrationMap = '/imgSvg/formIllustrationMap.svg';

// icons

const arrowRightBtnWhite = '/imgSvg/arrowRightBtnWhite.svg';
const arrowRightBtnBlue = '/imgSvg/arrowRightBtnBlue.svg';

const webDev = '/imgSvg/ico/webDev-icon.svg';
const mobDev = '/imgSvg/ico/mobDev-icon.svg';
const design = '/imgSvg/ico/design-icon.svg';
const iot = '/imgSvg/ico/iot-icon.svg';
const devops = '/imgSvg/ico/devops-icon.svg';
const qa = '/imgSvg/ico/qa-icon.svg';
const tbt = '/imgSvg/ico/tbt-icon.svg';

const leftArrow = '/imgSvg/ico/leftArrowSlider-icon.svg';
const rightArrow = '/imgSvg/ico/rightArrowSlider-icon.svg';

const googlePartner = '/imgSvg/googlePartner.svg';
const awsPartner = '/imgSvg/awsPartner.svg';
const callWhite = '/imgSvg/callWhite.svg';

const getElem = (id) => document.getElementById(id);

const HomePage = (props) => {
   const [status, setStatus] = React.useState(false);

   // slider
   const feedbackSliderSettings = {
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      adaptiveHeight: true,
      nextArrow: <FeedbackNextArrow />,
      prevArrow: <FeedbackPrevArrow />,
      responsive: [
         {
            breakpoint: 768,
            settings: {
               slidesToShow: 1,
            }
         }
      ]
   };

   function FeedbackNextArrow(props) {
      const { className, style, onClick } = props;
      return (
         <img className="feedback__nextArrow" src={rightArrow} onClick={onClick} />
      );
   }

   function FeedbackPrevArrow(props) {
      const { className, style, onClick } = props;
      return (
         <img className="feedback__prevArrow" src={leftArrow} onClick={onClick} />
      );
   }

   const categories = [
      { title: "Ui/Ux Design", abbr: "ui" },
      { title: "Mobile Application", abbr: "mobile" },
      { title: "Web", abbr: "web" },
      { title: "Extension", abbr: "extension" },
      { title: "Education", abbr: "education" },
   ]

   const [selectedCategory, setSelectedCategory] = React.useState(categories[0]);
   const [showAllCollections, setShowAllCollections] = React.useState(false);
   const [isHoveredBookCall, setIsHoveredBookCall] = React.useState(false);

   const selectCategory = (x) => {
      let category = categories.filter(y => y.abbr === x)
      setSelectedCategory(category[0])
   }

   const selectCategoryPhone = (x) => {
      let category = categories.filter(y => y.abbr === x)
      setSelectedCategory(category[0])
      setShowAllCollections(!showAllCollections)
   }

   const scrollToTop = () => {
      window.scrollTo(0, 0)
  }

  const onSubmitAction = (e) => {
     e.preventDefault();

     const nameFake = getElem('name_fake').value;
     const nameFake2 = getElem('name_fake2').value;
     const emailFake = getElem('email_fake').value;
     const name = getElem('name').value;
     const email = getElem('email').value;
     const message = getElem('message').value;

     const payload = {
         nameFake,
         nameFake2,
         emailFake,
         name,
         email,
         message
     };

   const headers = {
      'Content-Type': 'application/json'
    };

     fetch('/api/send', { method: 'post', headers, body: JSON.stringify(payload)}, resp => resp.json()).then(resp => {
        setStatus(true);
     }).catch(err => { setStatus(true); });
  }

   return (
      <>
         {/* {ContentData.Projects.filter(project => project.category.some(cat => cat === selectedCategory.abbr)).map((x, i) => {
                  return (
                     <img src={x.img} className="hidden w-32"/>
                  )})} */}
         <Menu />
         <section className="section sm:h-auto h-screen flex flex-col relative">
            <div className="banner">
               <div>
                  <h1 className="banner__slogan" data-aos="fade-down-right">Your <span className='text-blue'>trusted</span> software development team</h1>
                  <div className="text-lg sm:text-xl mt-5 sm:mt-10 w-9/12 mx-auto sm:mx-0 text-center sm:text-left sm:w-max">We take app delivering process off your shoulders</div>
                  <Link to="/#contact"><div className="leaveReqBtn mt-16 mx-auto sm:mx-0 sm:mt-24 w-max"><span>Leave Request</span><img className="ml-3" src={arrowRightBtnWhite}/></div></Link>
               </div>
               <div className="banner__illustration mt-16 sm:mt-0 relative md:absolute"><img src={banner_illustration} /></div>
            </div>

            <div className="hidden sm:flex flex-col sm:flex-row mt-12 md:mt-20 xl:mt-48 items-start sm:items-center flex-wrap">
               <div className="mr-16 my-5">
                  <div><img src={googlePartner} className="" /></div>
               </div>
               <div className="mr-16 my-5">
                  <div><img src={awsPartner} className="" /></div>
               </div>
               <div className="flex mr-16 my-5 items-center">
                  <div className=""><img className="w-full" src="./img/clutch.svg" /></div>
                  <div className="ml-4 w-28 sm:w-36 text-xs sm:text-sm">The Ukrainian Leaders in Software Development for 2020</div>
               </div>
            </div>
            <Link exact to="/#services" className="block sm:hidden absolute m-auto bottom-10 left-0 right-0 w-max"><img src="./imgSvg/ico/swipeIcon.svg" alt="swipe icon"/></Link>
         </section>

         <div className="overflow-hidden">
            <section className="section-services py-10 sm:py-16" id="services">
               <div className="section">
                  <div className="flex items-center">
                     <div className="text-xl md:text-3xl font-bold">What We <span className="text-blue">Do</span></div>
                     <Link to="/#contact"><div className="leaveReqBtn--light w-max ml-10"><span>Leave Request</span><img className="ml-3" src={arrowRightBtnBlue}/></div></Link>
                  </div>
                  <div className="skills">
                     <div className="skill">
                        <img src={iot} />
                        <div className="text-sm font-medium sm:font-bold">IOT</div>
                     </div>
                     <div className="skill">
                        <img src={qa} />
                        <div className="text-sm font-medium sm:font-bold">QA</div>
                     </div>

                     <div className="skill">
                        <img src={design} />
                        <div className="text-sm font-medium sm:font-bold">UI/UX Design</div>
                     </div>

                     <div className="skill">
                        <img src={tbt} />
                        <div className="text-sm font-medium sm:font-bold">TBD</div>
                     </div>

                     <div className="skill">
                        <img src={devops} />
                        <div className="text-sm font-medium sm:font-bold">DEVOPS</div>
                     </div>

                     <div className="skill">
                        <img src={webDev} />
                        <div className="text-sm font-medium sm:font-bold">WEB DEVELOPMENT</div>
                     </div>

                     <div className="skill">
                        <img src={mobDev} />
                        <div className="text-sm font-medium sm:font-bold">MOB DEVELOPMENT</div>
                     </div>
                  </div>
               </div>
            </section>
         </div>

         <div className="h-0 w-0">{ContentData.Projects.map(project => <img src={project.img}/>)}</div>

         <section className="section-feedback" id="reviews">
            <div className="section">
            <img src={feedback_illustration} className="feedback__bgIllustration" />
            <div className="text-xl md:text-3xl font-bold">What <span className="text-blue">Our Clients </span>Say</div>
            <div className="feedback__slider">
               <Slider {...feedbackSliderSettings}>

                  {ContentData.Feedbacks.map(feedback => {
                     return (
                        <div>
                           <div className="feedback">
                              <div className="text-base mt-3">“{feedback.feedback}”</div>
                              <div className="text-sm font-semibold w-10/12 md:w-9/12 xl:w-full leading-snug mt-5">{feedback.company}</div>
                              <div className="text-xs text-lightGray mt-2">{feedback.client}</div>
                              {/* <img src={feedbackIcon} className="absolute -top-5 right-5 sm:right-10 w-10 h-10 md:w-auto md:h-auto" /> */}
                           </div>
                        </div>
                     )
                  })}
               </Slider>
            </div>
            </div>
         </section>

         <section className="section-portfolio" id="portfolio">
            <div className="text-xl md:text-3xl font-bold">Our <span className="text-blue">Projects</span></div>
            <div className="w-full mt-16 hidden md:flex">
               <div className={selectedCategory.abbr === 'ui' ? "portfolio__menuItem--active" : "portfolio__menuItem"} onClick={() => selectCategory("ui")}>UI/UX Design</div>
               <div className={selectedCategory.abbr === 'mobile' ? "portfolio__menuItem--active" : "portfolio__menuItem"} onClick={() => selectCategory("mobile")}>Mobile Application</div>
               <div className={selectedCategory.abbr === 'web' ? "portfolio__menuItem--active" : "portfolio__menuItem"} onClick={() => selectCategory("web")}>Web</div>
               <div className={selectedCategory.abbr === 'extension' ? "portfolio__menuItem--active" : "portfolio__menuItem"} onClick={() => selectCategory("extension")}>Extension</div>
               <div className={selectedCategory.abbr === 'education' ? "portfolio__menuItem--active" : "portfolio__menuItem"} onClick={() => selectCategory("education")}>Education</div>
            </div>

            <div className="categories__dropdownWrapper block md:hidden">
               <div className={showAllCollections ? "border-0 categories__dropdownItem--active" : "border-b border-lilghtBlue categories__dropdownItem--active"} onClick={() => setShowAllCollections(!showAllCollections)}>{selectedCategory.title}<span>{showAllCollections ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}</span></div>
               <div className={showAllCollections ? "categories__dropdown--active" : "categories__dropdown"}>
                  <div className="categories__dropdownItem" onClick={() => selectCategoryPhone("ui")}>UI/UX Design</div>
                  <div className="categories__dropdownItem" onClick={() => selectCategoryPhone("mobile")}>Mobile Application</div>
                  <div className="categories__dropdownItem" onClick={() => selectCategoryPhone("web")}>Web</div>
                  <div className="categories__dropdownItem" onClick={() => selectCategoryPhone("extension")}>Extension</div>
                  <div className="categories__dropdownItem" onClick={() => selectCategoryPhone("education")}>Education</div>
               </div>
            </div>

            <Masonry
               breakpointCols={{ default: 3, 800: 2, 640: 1 }}
               className="portfolio__projects"
               columnClassName="portfolio__projectsColumn"
               // data-aos="fade-up"
               // data-aos-duration="1000"
               // data-aos-easing="ease-in-out"
               // data-aos-anchor-placement="top"
            >
               {ContentData.Projects.filter(project => project.category.some(cat => cat === selectedCategory.abbr)).map((x, i) => {
                  return (
                     <NavLink to={x.link} onClick={scrollToTop}>
                        <div className={i % 2 === 0 ? "portfolio__projectBox portfolio__projectBox--bigger" : "portfolio__projectBox"}>
                              <img src={x.img} className="portfolio__projectImg"/>
                           <div className="portfolio__projectDesc">
                              <div>
                                 <div className={x.color === "light" ? "text-lg text-white font-semibold" : "text-lg text-dark font-semibold"}>{x.name}</div>
                                 <div className={x.color === "light" ? "text-descWhite text-base" : "text-base"}>{x.desc}</div>
                              </div>
                              <div className={x.color === "light" ? "portfolio__arrow text-white" : "portfolio__arrow text-lightGray"}><ArrowForwardIosRoundedIcon /></div>
                           </div>
                        </div>
                     </NavLink>
                  )
               })
               }
            </Masonry>

         </section>

         <section className="section-technology" id="technologies">
            <div className="text-1xl md:text-3xl font-bold">Our <span className="text-blue">Technology</span></div>
            <div className="technologies mt-8 sm:mt-16">

               {ContentData.Technologies.map(technology => {
                  return (
                     <div className="technology">
                        <div><img src={technology.img} className="mx-auto" /></div>
                        <div className="text-sm sm:text-base font-normal mt-1 sm:mt-4 text-center leading-tight">{technology.name}</div>
                     </div>
                  )
               })}

            </div>
         </section>

         <section className="hidden sm:block py-0 md:py-8 bg-lightGray mt-16 md:mt-36" id="bookCall">
            <div className="section">
               <div className="py-8 px-8 md:py-20 sm:px-20 md:px-20 lg:px-32 xl:px-44">
                  <div className="flex items-center flex-col md:flex-row justify-between relative z-20">
                     <div className="flex flex-col text-center md:text-left mr-0 md:mr-10">
                        <div className="font-semibold text-lg md:text-3xl">Book a Free Consultancy</div>
                        <div className="mt-3 sm:mt-5 w-auto w-full text-sm sm:text-base">Tell us about your project, and we will send you an estimate within 48 hours</div>
                     </div>
                     <a href='https://calendly.com/andreytyndyk/30min' className="w-full md:w-max" target='call'>
                        <button className="leaveReqBtn mt-10 md:mt-0 w-full md:w-max justify-center" onMouseEnter={() => setIsHoveredBookCall(!isHoveredBookCall)} onMouseLeave={() => setIsHoveredBookCall(!isHoveredBookCall)}>
                              <span>Book a Call</span>
                           <img src={callWhite} className="w-4 ml-3 lg:w-auto"/>
                        </button>
                     </a>
                  </div>
               </div>
            </div>
         </section>
         <section className="form__section" id="contact">
            <div className='form__wrapper px-0 md:px-14 lg:px-28 xl:px-44 py-0 md:py-10'>
               <div className='flex flex-col md:flex-row items-start md:items-center lg:items-start justify-between gap-10'>
                  <img src={formIllustration} className="hidden md:block w-max md:w-60 lg:w-max"/>
                  {!status && <form className="form" onSubmit={onSubmitAction}>
                     <div className="text-xl py-5 md:py-0 text-center md:text-left font-semibold">Get a Free Consultancy</div>
                     <div className="flex flex-col">
                        <input type="text" id='name_fake' className="hidden" name='Name' />
                        <div className="text-xs ml-3 mt-5">Name</div>
                        <input type="text" id='name' placeholder="Your Name" name="name_" className="form__input" />
                        <div className="text-xs ml-3 mt-5">E-mail</div>
                        <input type="email" id='email' placeholder="Your Email" name="email_" className="form__input" />
                        <input type="text" id='name_fake2' className="hidden" name='name' />
                        <input type="text" id='email_fake' className="hidden" name='email'/>
                     </div>
                     <div className="text-xs mt-5 ml-3">Message</div>
                     <textarea className="form__textarea" placeholder='Text your message' id='message' name='message' rows="5" cols="50"></textarea>
                     <div className="form__btn"><button type='submit'>Get Consultancy</button></div>
                  </form>}
               </div>
               {status && <div className="form text-lg font-semibold text-center">Message sent!</div>}
            </div>
            <img src={formIllustrationMap} className="mx-auto mt-28 px-5 hidden sm:block"/>
         </section>
         <Footer />
      </>
   )
}

export default HomePage

import React from 'react';
import './footer.scss';
import { HashLink as Link } from 'react-router-hash-link';
import { NavLink } from "react-router-dom";


const Footer = () => {
  return (
    <footer className="footer py-10 sm:py-24">
        <div className="section">
            <div className="flex justify-between items-center">
            <NavLink to="/" className="mr-5"><img src="../../imgSvg/logo.svg" className="cursor-pointer w-28 md:w-32 lg:w-full" /></NavLink>
            <div className="hidden lg:flex flex gap-10 mr-5">
                <Link exact to="/#services" className="text-dark hover:text-blue transition">Services</Link>
                <Link to="/#reviews" className="text-dark hover:text-blue transition">Reviews</Link>
                <Link exact to="/#technologies" className="text-dark hover:text-blue transition">Technologies</Link>
                <Link exact to="/#portfolio" className="text-dark hover:text-blue transition">Portfolio</Link>
                <Link exact to="/#contact" className="text-dark hover:text-blue transition">Contact Us</Link>
            </div>
            <div className="flex gap-5">
                <img src="./imgSvg/ico/facebook.svg"/>
                <img src="./imgSvg/ico/instagram.svg"/>
                <img src="./imgSvg/ico/linkedin.svg"/>
            </div>
        </div>

        <div className="flex lg:hidden flex flex-wrap gap-5 sm:gap-10 mt-14 mx-left sm:mx-auto justify-between md:justify-center w-8/12 sm:w-full">
                <Link exact to="/#services" className="text-dark hover:text-blue transition w-28 sm:w-max">Services</Link>
                <Link to="/#reviews" className="text-dark hover:text-blue transition w-28 sm:w-max">Reviews</Link>
                <Link exact to="/#technologies" className="text-dark hover:text-blue transition w-28 sm:w-max">Technologies</Link>
                <Link exact to="/#portfolio" className="text-dark hover:text-blue transition w-28 sm:w-max">Portfolio</Link>
                <Link exact to="/#contact" className="text-dark hover:text-blue transition w-28 sm:w-max">Contact Us</Link>
            </div>

        <hr className='border-lightGray mt-12'/>
        <div className='mt-7 text-center text-sm'>Tinsa Soft, 2021</div>
        </div>
    </footer>
  )
}

export default Footer
import React from 'react'
import { useLocation } from 'react-router-dom'
import './monit.scss'
import Menu from '../../components/menu/menu'
import OtherProjects from '../../components/otherProjects/otherProjects'

// images
const logo = '/imgSvg/monit/logo.svg'
const phone = '/imgSvg/monit/phone.svg'
const phones = '/imgSvg/monit/phones.svg'
const laptop = '/imgSvg/monit/laptop.svg'
const cards = '/imgSvg/monit/cards.svg'


// icons 
const icon1 = '/imgSvg/monit/ico/icon1.svg'
const icon2 = '/imgSvg/monit/ico/icon2.svg'
const icon3 = '/imgSvg/monit/ico/icon3.svg'
const icon4 = '/imgSvg/monit/ico/icon4.svg'
const icon5 = '/imgSvg/monit/ico/icon5.svg'
const icon6 = '/imgSvg/monit/ico/icon6.svg'
const icon7 = '/imgSvg/monit/ico/icon7.svg'
const icon8 = '/imgSvg/monit/ico/icon8.svg'
const icon9 = '/imgSvg/monit/ico/icon9.svg'


const Monit = () => {

   const location = useLocation();

   return (
      <div>
         <Menu mode="white"/>

         <div className="relative">
            <div className="hidden sm:block"><img className="w-full hidden sm:block" src='./img/monit/banner.png' /></div>

            <div className="block sm:hidden">
               <img className="tickets__phoneBg" src='./img/monit/banner.png' />
            </div>

            <div className="absoluteSection">
               <div className="" data-aos="fade"><img src={logo} className="w-32 md:w-1/4 lg:w-auto mx-auto sm:mx-0" /></div>
               <div className="text-center sm:text-left text-base md:text-lg lg:text-2xl mt-2 sm:mt-1 lg:mt-6 text-monit-gray">Mobile App for notification about stocks</div>
            </div>
         </div>

         <div className="monit__contentWrapper">

            <div className="work__section flex py-10 justify-center md:py-20 lg:py-24 flex flex-col-reverse md:flex-row md:justify-around items-center">
               <div className="flex flex-col mt-10 md-mt-0">
                  <div className="text-2xl md:text-3xl lg:text-4xl mx-auto text-center md:mx-0 md:text-left text-white" data-aos="fade-left" data-aos-duration="1000">Monitoring stocks</div>
                  <div className="text-base md:text-lg my-2 md:py-5 text-center sm:text-left text-monit-gray" data-aos="fade-left" data-aos-duration="1200">Add stocks to the watchlist and monitor the price</div>
               </div>
               <div className="mt-7 md:mt-0 w-5/12 md:w-auto"><img src={phone} className="mx-auto" /></div>
            </div>

            <div className="work__section px-5">
               <img className="mx-auto my-5 md:my-10 w-8/12 md:w-auto" src={laptop} />
            </div>

            <div className="work__section flex py-5 md:py-20 flex-wrap flex-col md:flex-row justify-between">

               <div className="flex flex-col mt-10 mr-10">
                  <div>
                     <div className="text-monit-gray uppercase text-base font-medium">colors</div>
                     <div className="flex mt-3 flex-wrap" data-aos="zoom-in">
                        <div className="bg-monit-blue w-12 h-12 rounded-full mr-3 mb-3"></div>
                        <div className="bg-monit-gray w-12 h-12 rounded-full mr-3 mb-3"></div>
                        <div className="bg-monit-darkGray w-12 h-12 rounded-full mr-3 mb-3"></div>
                        <div className="bg-monit-black w-12 h-12 rounded-full mr-3 v"></div>
                        <div className="bg-monit-red w-12 h-12 rounded-full mr-3 mb-3"></div>
                        <div className="bg-monit-green w-12 h-12 rounded-full mr-3 mb-3"></div>
                     </div>
                  </div>

                  <div className="mt-10 lg:mt-20">
                     <div className="text-monit-gray uppercase text-base font-medium">typography</div>
                     <div className="mt-3 text-4xl sm:text-6xl text-white font-light" data-aos="zoom-in">Poppins</div>
                     <div className="flex mt-3 items-center mt-10" data-aos="zoom-in">

                        <div className="flex flex-col text-white">
                           <div className="font-light text-sm sm:text-base">Light</div>
                           <div className="text-3xl sm:text-5xl font-light">Aa</div>
                        </div>
                        <div className="flex flex-col ml-14 text-white">
                           <div className="font-regular text-sm sm:text-base">Regular</div>
                           <div className="text-3xl sm:text-5xl font-regular">Aa</div>
                        </div>
                        <div className="flex flex-col ml-14 mt-1 text-white">
                           <div className="font-medium text-sm sm:text-base">Medium</div>
                           <div className="text-3xl sm:text-5xl font-medium">Aa</div>
                        </div>
                     </div>
                  </div>
               </div>

               <div className="flex flex-col flex-wrap mt-10 w-full lg:w-auto">
                  <div>
                     <div className="text-monit-gray uppercase text-base font-medium">icons</div>
                     <div className="mt-3 rw__colorsSectionIcons">
                        <img src={icon1} />
                        <img src={icon2} />
                        <img src={icon3} />
                        <img src={icon4} />
                        <img src={icon5} />
                        <img src={icon6} />
                        <img src={icon7} />
                        <img src={icon8} />
                        <img src={icon9} />
                     </div>
                  </div>
                  <div className="mt-10 lg:mt-20">
                     <div className="text-monit-gray uppercase text-base font-medium">card ui</div>
                     <div className="mt-3">
                        <div><img src={cards} /></div>
                     </div>
                  </div>
               </div>
            </div>

            <div className="work__section flex justify-center py-10 lg:py-24 flex flex-col-reverse md:flex-row md:justify-around items-center">
               <div className="mt-7 md:mt-0 w-7/12 md:w-auto"><img src={phones} className="mx-auto" /></div>
               <div className="flex flex-col mt-10 md-mt-0">
                  <div className="text-2xl md:text-3xl lg:text-4xl mx-auto text-center md:mx-0 md:text-left text-white" data-aos="fade-left" data-aos-duration="1000">Create notifications</div>
                  <div className="text-base md:text-lg my-2 md:py-5 text-center sm:text-left text-monit-gray" data-aos="fade-left" data-aos-duration="1200">Create notifications, add setup and alert type</div>
               </div>
            </div>

         </div>

         <OtherProjects location={location.pathname} />

      </div>
   )
}

export default Monit
